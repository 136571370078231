.hero-about--content {
  -skrollr-animation-name: aboutContent; }

@-skrollr-keyframes aboutContent {
  bottom-top {
    opacity: 0;
    transform: translateX(-100px) translateY(-50%); }

  center-top {
    opacity: 1;
    transform: translateX(0px) translateY(-50%); } }

.product-info.first {
  -skrollr-animation-name: productInfo; }

@-skrollr-keyframes productInfo {
  bottom-top {
    opacity: 0;
    transform: translateX(100px) translateY(-50%); }

  center-top {
    opacity: 1;
    transform: translateX(0px) translateY(-50%); } }

.awards .awards--left {
  -skrollr-animation-name: awardsLeft; }
.awards .awards--right {
  -skrollr-animation-name: awardsRight; }

.headline--bars {
  -skrollr-animation-name: titleBars; }

@-skrollr-keyframes titleBars {
  100-bottom-top {
    opacity: 0;
    transform: translateY(50px); }

  -100-bottom-bottom {
    opacity: 1;
    transform: translateY(0px); } }

@-skrollr-keyframes awardsLeft {
  300-bottom-center {
    transform: translateY(100px);
    opacity: 0; }

  bottom-center {
    transform: translateY(0);
    opacity: 1; } }

@-skrollr-keyframes awardsRight {
  300-bottom-center {
    transform: translateX(100px);
    opacity: 0; }

  bottom-bottom {
    transform: translateX(0);
    opacity: 1; } }

.buzz--article.first {
  -skrollr-animation-name: articleFirst; }

@-skrollr-keyframes articleFirst {
  bottom-top {
    opacity: 0;
    transform: translateY(60px); }

  bottom-bottom {
    opacity: 1;
    transform: translateY(0px); } }

.buzz--article.middle {
  -skrollr-animation-name: articleMiddle; }

@-skrollr-keyframes articleMiddle {
  bottom-top {
    opacity: 0; }

  bottom-bottom {
    opacity: 1; } }

.buzz--article.last {
  -skrollr-animation-name: articleLast; }

@-skrollr-keyframes articleLast {
  100-bottom-top {
    opacity: 0;
    transform: translateY(-80px); }

  bottom-bottom {
    opacity: 1;
    transform: translateY(0px); } }

.footer--copy {
  -skrollr-animation-name: footerIn; }

@-skrollr-keyframes footerIn {
  bottom-top {
    transform: translateY(20px); }

  bottom-bottom {
    transform: translateY(0px); } }

.input--zip {
  -skrollr-animation-name: zip; }

@-skrollr-keyframes zip {
  bottom-bottom {
    opacity: 0.6;
    transform: translateY(-10px); }

  -100-bottom {
    opacity: 1;
    transform: translateY(0px); } }

.input--submit {
  -skrollr-animation-name: submit; }

@-skrollr-keyframes submit {
  bottom-bottom {
    opacity: 0.6;
    transform: translateY(15px); }

  -150-bottom {
    opacity: 1;
    transform: translateY(0px); } }

